<!-- Careful, this component is built using two separate implementation for desktop and mobile! -->

<div
  #container
  id="menu-container"
  class="position-fixed top-0 w-100 text-white"
  [class.mobile-expanded]="!mobileMenuIsCollapsed"
  [class.desktop-dropdown-open]="
    !desktopProductMenuIsCollapsed || !desktopUseCasesMenuIsCollapsed
  "
>
  <div #topRow class="row g-0 px-2">
    <!-- Top-left logo -->
    <div class="col-auto d-flex align-items-center">
      <a class="px-4 py-3 me-2 link-like" routerLink="/">
        <img
          id="logo"
          src="assets/media/aposphere-logo-white.svg"
          aria-label="Aposphere"
        />
      </a>
    </div>

    <!-- Menu items in navbar on desktop devices -->
    <div class="col-auto d-none d-lg-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        routerLink="/services"
        routerLinkActive="fw-bold"
        [routerLinkActiveOptions]="{ exact: true }"
        ><ng-container *ngTemplateOutlet="services"></ng-container
      ></a>
    </div>
    <div class="col-auto d-none d-lg-block">
      <div
        ngbDropdown
        [open]="!desktopProductMenuIsCollapsed"
        (openChange)="desktopProductMenuIsCollapsed = !$event"
        display="static"
        class="d-inline-block"
      >
        <button
          type="button"
          class="btn btn-link text-white text-decoration-none link-like p-3 no-dropdown-arrow"
          id="productsDropdown"
          ngbDropdownToggle
        >
          <span class="me-1"
            ><ng-container *ngTemplateOutlet="products"></ng-container></span
          ><span
            class="d-inline-block icon-dropdown-arrow"
            [class.rotate-180]="!desktopProductMenuIsCollapsed"
          ></span>
        </button>
        <div
          ngbDropdownMenu
          aria-labelledby="productsDropdown"
          class="position-fixed w-100 desktop-dropdown border-0 p-0 m-0 rounded-0"
        >
          <div class="container-fluid small py-4 text-white">
            <div class="row g-4">
              <div class="col">
                <p class="lead mb-0 px-3">
                  <ng-container
                    *ngTemplateOutlet="productsTitle"
                  ></ng-container>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <hr class="mx-3" />
              </div>
            </div>
            <div class="row g-4">
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/products/insight"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container *ngTemplateOutlet="insight"></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="insightDescription"
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/products"
                  fragment="finance"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container *ngTemplateOutlet="finance"></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="financeDescription"
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/products"
                  fragment="bespoke"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container *ngTemplateOutlet="bespoke"></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="bespokeDescription"
                    ></ng-container>
                  </p>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <hr class="mx-3" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/products"
                  routerLinkActive="fw-bold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <ng-container
                    *ngTemplateOutlet="productsOverview"
                  ></ng-container>
                  <span class="ms-2">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto d-none d-lg-block">
      <div
        ngbDropdown
        [open]="!desktopUseCasesMenuIsCollapsed"
        (openChange)="desktopUseCasesMenuIsCollapsed = !$event"
        display="static"
        class="d-inline-block"
      >
        <button
          type="button"
          class="btn btn-link text-white text-decoration-none link-like p-3 no-dropdown-arrow"
          id="useCasesDropdown"
          ngbDropdownToggle
        >
          <span class="me-1"
            ><ng-container *ngTemplateOutlet="usecases"></ng-container></span
          ><span
            class="d-inline-block icon-dropdown-arrow"
            [class.rotate-180]="!desktopUseCasesMenuIsCollapsed"
          ></span>
        </button>
        <div
          ngbDropdownMenu
          aria-labelledby="productsDropdown"
          class="position-fixed w-100 desktop-dropdown border-0 p-0 m-0 rounded-0"
        >
          <div class="container-fluid small py-4 text-white">
            <div class="row g-4">
              <div class="col">
                <p class="lead mb-0 px-3">
                  <ng-container
                    *ngTemplateOutlet="usecasesInsightTitle"
                  ></ng-container>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <hr class="mx-3" />
              </div>
            </div>
            <div class="row g-4">
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases/business-strategy-consulting"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container
                      *ngTemplateOutlet="
                        usecasesBusinessStrategyConsultingTitle
                      "
                    ></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="
                        usecasesBusinessStrategyConsultingDescription
                      "
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases/annual-operation-plan"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecasesAnnualOperationPlanTitle"
                    ></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecasesAnnualOperationPlanDescription"
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases/esg"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecasesESGTitle"
                    ></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecasesESGDescription"
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases/5-year-operation-plan"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecases5YearOperationPlanTitle"
                    ></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecases5YearOperationPlanDescription"
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases/results-based-project-management"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container
                      *ngTemplateOutlet="
                        usecasesResultsBasedProjectManagementTitle
                      "
                    ></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="
                        usecasesResultsBasedProjectManagementDescription
                      "
                    ></ng-container>
                  </p>
                </a>
              </div>
              <div class="col desktop-product-link-col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases/conservation-diagrams"
                  routerLinkActive="fw-semibold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <p class="fs-5 mb-0">
                    <ng-container
                      *ngTemplateOutlet="usecasesConservationDiagramsTitle"
                    ></ng-container>
                  </p>
                  <p class="mb-0">
                    <ng-container
                      *ngTemplateOutlet="
                        usecasesConservationDiagramsDescription
                      "
                    ></ng-container>
                  </p>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <hr class="mx-3" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <a
                  class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                  routerLink="/use-cases"
                  routerLinkActive="fw-bold"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ngbDropdownItem
                >
                  <ng-container
                    *ngTemplateOutlet="usecasesInsightOverview"
                  ></ng-container>
                  <span class="ms-2">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto d-none d-lg-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        routerLink="/products/insight"
        fragment="pricing"
      >
        <ng-container *ngTemplateOutlet="pricing"></ng-container>
      </a>
    </div>
    <div class="col-auto d-none d-lg-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        routerLink="/concept"
      >
        <ng-container *ngTemplateOutlet="concept"></ng-container>
      </a>
    </div>

    <div class="col-auto flex-grow-1"></div>

    <div class="col-auto d-none d-lg-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        href="mailto:info@aposphere.com"
      >
        <ng-container *ngTemplateOutlet="contact"></ng-container>
      </a>
    </div>

    <!-- Hamburger menu on mobile devices -->
    <div class="col-auto d-lg-none">
      <button
        type="button"
        class="btn btn-link text-white text-decoration-none link-like mx-1 fs-3 pe-auto"
        (click)="mobileMenuCollapse.toggle()"
        aria-label="Toggle menu"
        [attr.aria-expanded]="!mobileMenuIsCollapsed"
        aria-controls="mobileMenuCollapse"
      >
        <span *ngIf="mobileMenuIsCollapsed" class="icon-menu"></span>
        <span *ngIf="!mobileMenuIsCollapsed" class="icon-close"></span>
      </button>
    </div>
  </div>

  <!-- Content of the mobile hamburger menu -->
  <div
    #mobileMenuCollapse="ngbCollapse"
    [(ngbCollapse)]="mobileMenuIsCollapsed"
    class="row d-lg-none g-0 w-100 pe-auto mb-3"
  >
    <div class="col-auto w-100">
      <!-- Services -->
      <div class="row g-0 overflow-hidden">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100 text-start"
            routerLink="/services"
            routerLinkActive="fw-bold"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
          >
            <ng-container *ngTemplateOutlet="services"></ng-container>
          </a>
        </div>
      </div>

      <!-- Products -->
      <div class="row g-0 overflow-hidden">
        <div class="col-auto w-100">
          <button
            type="button"
            class="btn btn-link text-white text-decoration-none link-like d-flex align-items-center w-100 text-start"
            (click)="mobileProductsMenuCollapse.toggle()"
          >
            <span class="me-1"
              ><ng-container *ngTemplateOutlet="products"></ng-container></span
            ><span
              class="icon-dropdown-arrow"
              [class.rotate-180]="!mobileProductsMenuIsCollapsed"
            ></span>
          </button>
        </div>
      </div>
      <div class="row g-0 small overflow-hidden">
        <div class="col-auto w-100">
          <div
            #mobileProductsMenuCollapse="ngbCollapse"
            [(ngbCollapse)]="mobileProductsMenuIsCollapsed"
            class="row g-0 mobile-submenu"
          >
            <div class="col-auto w-100">
              <div class="row g-0">
                <div class="col">
                  <p class="lead fs-6 mb-0 px-button py-button">
                    <ng-container
                      *ngTemplateOutlet="productsTitle"
                    ></ng-container>
                  </p>
                </div>
              </div>
              <div class="row px-button">
                <div class="col">
                  <hr class="mt-0" />
                </div>
              </div>
              <div class="row px-button g-3">
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/products/insight"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="6 mb-0">
                      <ng-container *ngTemplateOutlet="insight"></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="insightDescription"
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/products"
                    fragment="finance"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container *ngTemplateOutlet="finance"></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="financeDescription"
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/products"
                    fragment="bespoke"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container *ngTemplateOutlet="bespoke"></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="bespokeDescription"
                      ></ng-container>
                    </p>
                  </a>
                </div>
              </div>
              <div class="row px-button">
                <div class="col">
                  <hr class="mb-0" />
                </div>
              </div>
              <div class="row g-0">
                <div class="col px-button py-button">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/products"
                    routerLinkActive="fw-bold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <ng-container
                      *ngTemplateOutlet="productsOverview"
                    ></ng-container>
                    <span class="ms-2">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0 overflow-hidden">
        <div class="col-auto w-100">
          <button
            type="button"
            class="btn btn-link text-white text-decoration-none link-like d-flex align-items-center w-100 text-start"
            (click)="mobileUseCasesMenuCollapse.toggle()"
          >
            <span class="me-1"
              ><ng-container *ngTemplateOutlet="usecases"></ng-container></span
            ><span
              class="icon-dropdown-arrow"
              [class.rotate-180]="!mobileUseCasesMenuIsCollapsed"
            ></span>
          </button>
        </div>
      </div>
      <div class="row g-0 small overflow-hidden">
        <div class="col-auto w-100">
          <div
            #mobileUseCasesMenuCollapse="ngbCollapse"
            [(ngbCollapse)]="mobileUseCasesMenuIsCollapsed"
            class="row g-0 mobile-submenu"
          >
            <div class="col-auto w-100">
              <div class="row g-0">
                <div class="col">
                  <p class="lead fs-6 mb-0 px-button py-button">
                    <ng-container
                      *ngTemplateOutlet="usecasesInsightTitle"
                    ></ng-container>
                  </p>
                </div>
              </div>
              <div class="row px-button">
                <div class="col">
                  <hr class="mt-0" />
                </div>
              </div>
              <div class="row px-button g-3">
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases/business-strategy-consulting"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecasesBusinessStrategyConsultingTitle
                        "
                      ></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecasesBusinessStrategyConsultingDescription
                        "
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases/annual-operation-plan"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container
                        *ngTemplateOutlet="usecasesAnnualOperationPlanTitle"
                      ></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecasesAnnualOperationPlanDescription
                        "
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases/esg"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container
                        *ngTemplateOutlet="usecasesESGTitle"
                      ></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="usecasesESGDescription"
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases/5-year-operation-plan"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container
                        *ngTemplateOutlet="usecases5YearOperationPlanTitle"
                      ></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecases5YearOperationPlanDescription
                        "
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases/results-based-project-management"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecasesResultsBasedProjectManagementTitle
                        "
                      ></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecasesResultsBasedProjectManagementDescription
                        "
                      ></ng-container>
                    </p>
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases/conservation-diagrams"
                    routerLinkActive="fw-semibold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <p class="fs-6 mb-0">
                      <ng-container
                        *ngTemplateOutlet="usecasesConservationDiagramsTitle"
                      ></ng-container>
                    </p>
                    <p class="small mb-0">
                      <ng-container
                        *ngTemplateOutlet="
                          usecasesConservationDiagramsDescription
                        "
                      ></ng-container>
                    </p>
                  </a>
                </div>
              </div>
              <div class="row px-button">
                <div class="col">
                  <hr class="mb-0" />
                </div>
              </div>
              <div class="row g-0">
                <div class="col px-button py-button">
                  <a
                    class="bg-transparent text-white text-decoration-none link-like text-start text-wrap"
                    routerLink="/use-cases"
                    routerLinkActive="fw-bold"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeOverlays()"
                  >
                    <ng-container
                      *ngTemplateOutlet="usecasesInsightOverview"
                    ></ng-container>
                    <span class="ms-2">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0 overflow-hidden">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100 text-start"
            routerLink="/products/insight"
            fragment="pricing"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            ><ng-container *ngTemplateOutlet="pricing"></ng-container
          ></a>
        </div>
      </div>
      <div class="row g-0 overflow-hidden">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100 text-start"
            routerLink="/concept"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            ><ng-container *ngTemplateOutlet="concept"></ng-container
          ></a>
        </div>
      </div>
      <div class="row g-0 overflow-hidden">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100 text-start"
            href="mailto:info@aposphere.com"
            (click)="
              mobileMenuIsCollapsed = true; mobileUseCasesMenuIsCollapsed = true
            "
            ><ng-container *ngTemplateOutlet="contact"></ng-container
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Background blocker when mobile menu or desktop dropdown is active -->
<div
  *ngIf="
    !mobileMenuIsCollapsed ||
    !desktopProductMenuIsCollapsed ||
    !desktopUseCasesMenuIsCollapsed
  "
  class="position-fixed p-2 top-0 w-100 h-100 bg-black opacity-75"
  (click)="closeOverlays()"
></div>

<!-- Common texts for desktop and mobile -->
<!-- Services -->
<ng-template #services i18n="services|services link@@core.header.services"
  >Services</ng-template
>

<!-- Products -->
<ng-template #products i18n="products|products link@@core.header.products"
  >Products</ng-template
>
<ng-template
  #productsTitle
  i18n="products-title|products link@@core.header.products-title"
  >Our Products</ng-template
>
<ng-template
  #productsOverview
  i18n="products-overview|products link@@core.header.products-overview"
  >See all our products</ng-template
>
<ng-template #insight i18n="insight|insight link@@core.header.insight"
  >Aposphere Insight</ng-template
>
<ng-template
  #insightDescription
  i18n="insight-description|insight link@@core.header.insight-description"
  >Manage your whole institutional knowledge chain including strategy,
  performance evaluation, and workplan.</ng-template
>
<ng-template #finance i18n="finance|finance link@@core.header.finance"
  >Aposphere Finance & Controlling</ng-template
>
<ng-template
  #financeDescription
  i18n="finance-description|finance link@@core.header.finance-description"
  >Your integrated accounting, controlling, and budgeting solution.</ng-template
>
<ng-template #bespoke i18n="bespoke|bespoke link@@core.header.bespoke"
  >Bespoke Solutions</ng-template
>
<ng-template
  #bespokeDescription
  i18n="bespoke-description|bespoke link@@core.header.bespoke-description"
  >Custom tailored software solutions meeting your specific needs.</ng-template
>

<!-- Use Cases -->
<ng-template #usecases i18n="usecases|usecases link@@core.header.usecases"
  >Use Cases</ng-template
>
<ng-template
  #usecasesInsightOverview
  i18n="
    usecases-insight-overview|usecases-insight
    link@@core.header.usecases-insight-overview"
  >See all use cases</ng-template
>

<ng-template
  #usecasesInsightTitle
  i18n="
    usecases-insight-title|usecases-insight-title
    link@@core.header.usecases-insight-title"
  >Learn how other people use Aposphere Insight</ng-template
>
<ng-template
  #usecasesBusinessStrategyConsultingTitle
  i18n="
    usecases-business-strategy-consulting-title|usecases-business-strategy-consulting-title
    link@@core.header.usecases-business-strategy-consulting-title"
  >Business Strategy Development</ng-template
>
<ng-template
  #usecasesBusinessStrategyConsultingDescription
  i18n="
    usecases-business-strategy-consulting-description|usecases-business-strategy-consulting-description
    link@@core.header.usecases-business-strategy-consulting-description"
  >Visual diagrams linking actions to strategic goals.</ng-template
>
<ng-template
  #usecasesAnnualOperationPlanTitle
  i18n="
    usecases-annual-operation-plan-title|usecases-annual-operation-plan-title
    link@@core.header.usecases-annual-operation-plan-title"
  >Annual Operation Plan</ng-template
>
<ng-template
  #usecasesAnnualOperationPlanDescription
  i18n="
    usecases-annual-operation-plan-description|usecases-annual-operation-plan-description
    link@@core.header.usecases-annual-operation-plan-description"
  >Collaborative annual planning connected to Salesforce CRM and Asana project
  management.</ng-template
>
<ng-template
  #usecasesESGTitle
  i18n="
    usecases-esg-title|usecases-esg-title link@@core.header.usecases-esg-title"
  >ESG Reporting</ng-template
>
<ng-template
  #usecasesESGDescription
  i18n="
    usecases-esg-description|usecases-esg-description
    link@@core.header.usecases-esg-description"
  >Yearly reporting on the environmental, social, and governance (ESG)
  performance of a company.</ng-template
>
<ng-template
  #usecases5YearOperationPlanTitle
  i18n="
    usecases-5-year-operation-plan-title|usecases-5-year-operation-plan-title
    link@@core.header.usecases-5-year-operation-plan-title"
  >5-Year Operation Plan</ng-template
>
<ng-template
  #usecases5YearOperationPlanDescription
  i18n="
    usecases-5-year-operation-plan-description|usecases-5-year-operation-plan-description
    link@@core.header.usecases-5-year-operation-plan-description"
  >Multi-level planning with agile adaptation.</ng-template
>
<ng-template
  #usecasesResultsBasedProjectManagementTitle
  i18n="
    usecases-results-based-project-management-title|usecases-results-based-project-management-title
    link@@core.header.usecases-results-based-project-management-title"
  >Results-Based Project Management</ng-template
>
<ng-template
  #usecasesResultsBasedProjectManagementDescription
  i18n="
    usecases-results-based-project-management-description|usecases-results-based-project-management-description
    link@@core.header.usecases-results-based-project-management-description"
  >Agile task management with progress tracking and impact
  evaluation.</ng-template
>
<ng-template
  #usecasesConservationDiagramsTitle
  i18n="
    usecases-conservation-diagrams-title|usecases-conservation-diagrams-title
    link@@core.header.usecases-conservation-diagrams-title"
  >Nature Conservation Strategy</ng-template
>
<ng-template
  #usecasesConservationDiagramsDescription
  i18n="
    usecases-conservation-diagrams-description|usecases-conservation-diagrams-description
    link@@core.header.usecases-conservation-diagrams-description"
  >Data-driven strategic planning in nature conservation.</ng-template
>

<!-- Get in touch -->
<ng-template #contact i18n="contact|contact link@@core.header.contact"
  >Get in touch</ng-template
>

<!-- Pricing -->
<ng-template #pricing i18n="pricing|pricing link@@core.header.pricing"
  >Pricing</ng-template
>

<!-- Concept -->
<ng-template #concept i18n="concept|concept link@@core.header.concept"
  >Our Vision</ng-template
>
