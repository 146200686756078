<!-- Mailchimp -->
<div class="container-fluid position-relative">
  <div
    id="mailchimp-image"
    class="row position-absolute top-0 w-100"
    [style.background-image]="'url(assets/media/cover.webp)'"
  ></div>

  <div
    id="mailchimp-content"
    class="row justify-content-center align-items-center"
  >
    <div class="col">
      <div class="container bg-white rounded-4 p-4 box-shadow">
        <div class="row align-items-center g-5">
          <div class="col-12 col-md-6">
            <h2
              i18n="
                subscribe-title|Subscribe to newsletter
                (title)@@core.footer.subscribe-title"
            >
              Stay in the loop
            </h2>
          </div>
          <div class="col-12 col-md-6">
            <form
              ngNoForm
              action="https://aposphere.us20.list-manage.com/subscribe/post?u=e87b40d7a2eb62e93e66110e3&amp;id=abb4a51e5c&amp;f_id=004602eaf0"
              method="POST"
              (submit)="mailchimpSubscribed = true"
            >
              <input
                class="form-control rounded-5 mb-3"
                type="email"
                name="EMAIL"
                placeholder="Your email address"
                i18n-placeholder="
                  subscribe-mail-placeholder|Subscribe to newsletter (mail input
                  placeholder)@@core.footer.subscribe-mail-placeholder"
              />
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input
                  type="text"
                  name="b_e87b40d7a2eb62e93e66110e3_abb4a51e5c"
                  tabindex="-1"
                  value=""
                />
              </div>
              <button
                class="btn btn-primary"
                type="submit"
                i18n="
                  subscribe-submit-button|Subscribe to newsletter
                  (submit-button)@@core.footer.subscribe-submit-button"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div *ngIf="mailchimpSubscribed" class="row mt-3">
          <div class="col">
            <p
              i18n="
                subscribe-confirmation|Subscribe to newsletter
                (confirmation)@@core.footer.subscribe-confirmation"
            >
              Thank you for your sign-up request! Please check your email inbox
              to confirm.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-dark text-white py-3">
  <div class="row g-0">
    <!-- Copyright notice on the left on desktop devices -->
    <div
      class="d-none d-lg-flex col-auto p-2 justify-content-center align-items-center small"
      i18n="copyright|copyright notice@@core.footer.copyright-desktop"
    >
      © 2023 Aposphere GmbH. All rights reserved
    </div>
    <div class="col-auto flex-grow-1"></div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        href="mailto:info@aposphere.com"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="contact|contact button@@core.footer.contact"
        >Contact</a
      >
    </div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/tos"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="tos-link|Terms of service link@@core.footer.tos-link"
        >Terms of Service</a
      >
    </div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/privacy"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="privacy-link|Privacy statement link@@core.footer.privacy-link"
        >Privacy Statement</a
      >
    </div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/legal"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="legal-link|legal notice link@@core.footer.legal-link"
        >Legal</a
      >
    </div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <button
        (click)="cookieConsentService.openSettingsDialog$.next()"
        type="button"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="cookie-settings|open cookie settings@@core.footer.cookie-settings"
      >
        Cookies
      </button>
    </div>
    <!-- Copyright notice on the bottom on mobile devices -->
    <div
      class="d-flex d-lg-none col-12 p-2 justify-content-center align-items-center small pt-4"
      i18n="copyright|copyright notice@@core.footer.copyright-mobile"
    >
      © 2023 Aposphere GmbH. All rights reserved
    </div>
  </div>
</div>
